
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_searchSales } from "@/functions/Cl_searchSales";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { DtoSearchSalesGetReq } from "furukawa-common/src/common/dto/DtoSearchSalesGetReq";
import { DtoSearchSalesGetRes } from "furukawa-common/src/common/dto/DtoSearchSalesGetRes";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
// import {
//   CONST_OP_SUPPLY_TYPE,
//   CONST_OP_ITEMTYPE,
// } from "furukawa-common/src/common/constant/Constants";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import {
  CONST_CLAIM_ROUDING_SISYAGONYU,
  CONST_OP_ITEMTYPE_BREAKDOWNS,
  CONST_OP_ITEMTYPE_ITEM,
  CONST_OP_ITEMTYPE_SUBJECTS,
  CONST_OP_PROJECT_TYPE,
  CONST_SORT_ORDER_ASC,
  CONST_SORT_ORDER_DESC,
} from "furukawa-common/src/common/constant/Constants";
import { DtoFileReportsGetReqImple } from "furukawa-common/src/common/dto/DtoFileReportsGetReqImple";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { DtoOutputqueuesGetReqImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetResImple";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
// import { CONST_OP_SUPPLY_TYPE } from "furukawa-common/src/common/constant/Constants";
import { CommonPrice4FU } from "furukawa-common/src/common/function/CommonPrice4FU";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Mt_dtp,
    Mt_dtp4month,
  },
})
export default class SalesSearch extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSearchSalesGetRes();
  delegate4list = new DtoMastersGetRes();
  delegateStaff = new DtoMastersGetRes();
  delegateClient = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;
  //検索条件dom
  // domProjectNo_start = "";
  // domProjectNo_end = "";
  domProjectName = "";
  domProClientid = "";
  domProjectSponsorName = "";
  domProStaffId = "";
  // domClaimNo_start = "";
  // domClaimNo_end = "";
  // domSalesIssueDate_start: Date | null = null;
  // domSalesIssueDate_end: Date | null = null;
  domSalesMonth_start = new DtoMdDtp();
  domSalesMonth_end = new DtoMdDtp();
  domSalesItem = "";
  domSalesSubject = "";
  domSalesBreakdown = "";
  domSalesClientid = "";
  domSalesRemarks = "";
  domProtype = "";
  domSalesDateExpPayment_start = new DtoMdDtp();
  domSalesDateExpPayment_end = new DtoMdDtp();
  domBranchId = "";

  domVariationType = "";
  domClientGenre = "";
  domItemType = "";

  strVariationType = [
    "",
    "媒体・製作施工",
    "媒体・施工",
    "製作施工",
    "媒体費",
    "施工費",
    "取付費",
    "撤去費",
    "撤去（前受）",
    "プリント",
    "製作費",
    "工事（資産）",
    "電気代",
    "申請費",
    "その他",
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opPjtype = CONST_OP_PROJECT_TYPE;
  opFormCategory = CONST_OP_ITEMTYPE_ITEM;
  opFormSubject = CONST_OP_ITEMTYPE_SUBJECTS;
  opFormBreakdown = CONST_OP_ITEMTYPE_BREAKDOWNS;

  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      width: "10%",
    },
    {
      label: "案件番号",
      field: "c_project_number",
      thClass: "text-center",
    },
    {
      label: "拠点",
      field: "c_branch_name",
      thClass: "text-center",
    },
    {
      label: "案件名",
      field: "c_project_name",
      thClass: "text-center",
    },
    {
      label: "広告主",
      field: "c_project_sponsor_name",
      thClass: "text-center",
    },
    {
      label: "請求No",
      field: "c_claim_id",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "売上月",
      field: "c_sales_month",
      type: "date",
      dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      dateOutputFormat: "yyyy-MM",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "項目",
      field: "c_sales_item_name",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "科目",
      field: "c_sales_subject_name",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "内訳",
      field: "c_sales_breakdown_name",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "売上先",
      field: "c_client_name",
      thClass: "text-center",
    },
    {
      label: "入力金額",
      field: "c_sales_price",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "税率",
      field: "c_sales_taxrate_name",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "税抜金額",
      field: "zeinuki",
    },
    {
      label: "税込金額",
      field: "c_sales_taxrate",
    },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //     label: "詳細",
  //     field: "btnDet",
  //   },
  //   {
  //     label: "案件番号",
  //     field: "c_project_number",
  //   },
  //   {
  //     label: "案件名",
  //     field: "c_project_name",
  //   },
  //   {
  //     label: "請求先",
  //     field: "pro_c_client_name",
  //   },
  //   {
  //     label: "広告主",
  //     field: "c_project_sponsor_name",
  //   },
  //   {
  //     label: "請求no",
  //     field: "c_claim_id",
  //   },
  //   {
  //     label: "売上月",
  //     field: "c_sales_month",
  //     type: "date",
  //     dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
  //     dateOutputFormat: "yyyy/MM",
  //   },
  //   {
  //     label: "担当者",
  //     field: "c_staff_name",
  //   },
  //   {
  //     label: "商品名",
  //     field: "c_item_name",
  //   },
  //   {
  //     label: "バリエーション名",
  //     field: "c_variation_name",
  //   },
  //   {
  //     label: "項目",
  //     field: "c_variation_type",
  //   },
  //   {
  //     label: "項目",
  //     field: "c_sales_item_name",
  //   },
  //   {
  //     label: "科目",
  //     field: "c_sales_subject_name",
  //   },
  //   {
  //     label: "内訳",
  //     field: "c_sales_breakdown_name",
  //   },
  //   {
  //     label: "売上先",
  //     field: "c_client_name",
  //   },
  //   {
  //     label: "金額",
  //     field: "c_sales_price",
  //   },
  // ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  //----------------------
  //モーダル用プロパティ
  //----------------------
  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
    await this.getMastersList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;

      dtoMaster.getBranch = 1;
      dtoMaster.delFlag4Branch = 0;

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 0;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSearchSalesGetReq();

      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;
      Cl_common.logger("pager");
      Cl_common.logger(dto.pager.count);
      Cl_common.logger(dto.pager.cursor);

      // if (this.domProjectNo_start != "")
      //   dto.projectNo_start = Number(this.domProjectNo_start);

      // if (this.domProjectNo_end != "")
      //   dto.projectNo_end = Number(this.domProjectNo_end);

      dto.projectName = this.domProjectName;

      if (this.domProClientid != "" && this.domProClientid != null)
        dto.proClientid = Number(this.domProClientid);

      dto.projectSponsorName = this.domProjectSponsorName;

      if (this.domProStaffId != "" && this.domProStaffId != null)
        //dto.staffId = Number(this.domProStaffId);
        dto.staffId = this.domProStaffId;

      // if (this.domClaimNo_start != "")
      //   dto.claimNo_start = Number(this.domClaimNo_start);

      // if (this.domClaimNo_end != "")
      //   dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.salesIssueDate_start = this.domSalesIssueDate_start;

      // dto.salesIssueDate_end = this.domSalesIssueDate_end;

      // dto.salesMonth_start = this.domSalesMonth_start;

      if (this.domSalesMonth_start.date != null) {
        dto.salesMonth_start = new Date(this.domSalesMonth_start.date);
      }

      // dto.salesMonth_end = this.domSalesMonth_end;

      if (this.domSalesMonth_end.date != null) {
        dto.salesMonth_end = new Date(this.domSalesMonth_end.date);
      }

      if (this.domSalesItem != "" && this.domSalesItem != null)
        dto.salesItem = Number(this.domSalesItem);

      if (this.domSalesSubject != "" && this.domSalesSubject != null)
        dto.salesSubject = Number(this.domSalesSubject);

      if (this.domSalesBreakdown != "" && this.domSalesBreakdown != null)
        dto.salesBreakdown = Number(this.domSalesBreakdown);

      if (this.domSalesClientid != "" && this.domSalesClientid != null)
        dto.salesClientid = Number(this.domSalesClientid);

      dto.SalesRemarks = this.domSalesRemarks;

      if (this.domVariationType != "" && this.domVariationType != null)
        dto.VariationType = Number(this.domVariationType);

      if (this.domClientGenre != "" && this.domClientGenre != null)
        dto.ClientGenre = Number(this.domClientGenre);

      if (this.domItemType != "" && this.domItemType != null)
        dto.ItemType = Number(this.domItemType);

      if (this.domProtype != "" && this.domProtype != null)
        dto.projectType = Number(this.domProtype);

      // dto.salesDateExpPayment_start = this.domSalesDateExpPayment_start;

      if (this.domSalesDateExpPayment_start.date != null) {
        dto.salesDateExpPayment_start = new Date(
          this.domSalesDateExpPayment_start.date
        );
      }

      // dto.salesDateExpPayment_end = this.domSalesDateExpPayment_end;

      if (this.domSalesDateExpPayment_end.date != null) {
        dto.salesDateExpPayment_end = new Date(
          this.domSalesDateExpPayment_end.date
        );
      }

      if (this.domBranchId != "" && this.domBranchId != null)
        dto.branchId = this.domBranchId;

      //API実行
      //-------
      this.delegate = await Cl_searchSales.getList(dto);
      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoSearchSalesGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl(sortOrder: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "UriageList";

      // if (this.domProjectNo_start != "")
      //   dto.projectNo_start = Number(this.domProjectNo_start);

      // if (this.domProjectNo_end != "")
      //   dto.projectNo_end = Number(this.domProjectNo_end);

      dto.projectName = this.domProjectName;

      if (this.domProClientid != "" && this.domProClientid != null)
        dto.proClientid = Number(this.domProClientid);

      dto.projectSponsorName = this.domProjectSponsorName;

      if (this.domProStaffId != "" && this.domProStaffId != null)
        dto.staffId = this.domProStaffId;

      // if (this.domClaimNo_start != "")
      //   dto.claimNo_start = Number(this.domClaimNo_start);

      // if (this.domClaimNo_end != "")
      //   dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.salesIssueDate_start = this.domSalesIssueDate_start;

      // dto.salesIssueDate_end = this.domSalesIssueDate_end;

      // dto.salesMonth_start = this.domSalesMonth_start;

      if (this.domSalesMonth_start.date != null) {
        dto.salesMonth_start = new Date(this.domSalesMonth_start.date);
      }

      // dto.salesMonth_end = this.domSalesMonth_end;

      if (this.domSalesMonth_end.date != null) {
        dto.salesMonth_end = new Date(this.domSalesMonth_end.date);
      }

      if (this.domSalesItem != "" && this.domSalesItem != null)
        dto.salesItem = Number(this.domSalesItem);

      if (this.domSalesSubject != "" && this.domSalesSubject != null)
        dto.salesSubject = Number(this.domSalesSubject);

      if (this.domSalesBreakdown != "" && this.domSalesBreakdown != null)
        dto.salesBreakdown = Number(this.domSalesBreakdown);

      if (this.domSalesClientid != "" && this.domSalesClientid != null)
        dto.salesClientid = Number(this.domSalesClientid);

      dto.SalesRemarks = this.domSalesRemarks;

      if (this.domVariationType != "" && this.domVariationType != null)
        dto.VariationType = Number(this.domVariationType);

      if (this.domClientGenre != "" && this.domClientGenre != null)
        dto.ClientGenre = Number(this.domClientGenre);

      if (this.domItemType != "" && this.domItemType != null)
        dto.ItemType = Number(this.domItemType);

      if (this.domProtype != "" && this.domProtype != null)
        dto.projectType = Number(this.domProtype);

      // dto.salesDateExpPayment_start = this.domSalesDateExpPayment_start;

      if (this.domSalesDateExpPayment_start.date != null) {
        dto.salesDateExpPayment_start = new Date(
          this.domSalesDateExpPayment_start.date
        );
      }

      // dto.salesDateExpPayment_end = this.domSalesDateExpPayment_end;

      if (this.domSalesDateExpPayment_end.date != null) {
        dto.salesDateExpPayment_end = new Date(
          this.domSalesDateExpPayment_end.date
        );
      }

      Cl_common.logger("domBranchId");
      Cl_common.logger(this.domBranchId);

      if (this.domBranchId != "" && this.domBranchId != null)
        dto.branchId = Number(this.domBranchId);

      // 引数に応じてソート方法を切り替える
      dto.sortorder = sortOrder ? CONST_SORT_ORDER_DESC : CONST_SORT_ORDER_ASC;

      //API実行
      //-------
      Cl_common.logger("dto");
      Cl_common.logger(dto);
      const res = await Cl_filereport.get(dto);
      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      // //レスポンス処理
      // //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 税抜き金額を計算する
   * @param salesPrice
   * @param taxRate
   */
  cnvExcludingTaxPrice(salesPrice: any, taxRate: any) {
    let res;

    let rounding = CONST_CLAIM_ROUDING_SISYAGONYU;

    //金額
    if (salesPrice != null) {
      res = CommonPrice4FU.calcExcludTax(salesPrice, taxRate, rounding);
    } else {
      res = "";
    }
    return res;
  }
  /**
   * 税込み金額を計算する
   * @param salesPrice
   * @param taxRate
   */
  cnvIncludingTaxPrice(salesPrice: any, taxRate: any) {
    let res;

    let rounding = CONST_CLAIM_ROUDING_SISYAGONYU;

    //金額
    if (salesPrice != null) {
      res = CommonPrice4FU.calcTax(salesPrice, taxRate, rounding);
    } else {
      res = "";
    }
    return res;
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/AnkenInput?id=" + id);
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }

  async cnvStrArray2NumArray(strArray: string[]): Promise<number[]> {
    let res: number[] = [];

    for (let i = 0; i < strArray.length; ++i) {
      res.push(Number(strArray[i]));
    }

    return res;
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
