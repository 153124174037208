
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import {
  CONST_APPROVAL_STATUS_SYOUNIN,
  CONST_REQUEST_STATUS_SASIMODOSI,
  CONST_REQUEST_STATUS_SINSEITYU,
  CONST_REQUEST_STATUS_SYOUNINZUMI,
  CONST_REQUEST_STATUS_TORISAGE,
} from "furukawa-common/src/common/constant/Constants";
// masters
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
//残業時間
import { Cl_requesttmppay } from "@/functions/Cl_requesttmppay";
import { DtoRequestTmppaysGetReqImple } from "furukawa-common/src/common/dto/DtoRequestTmppaysGetReqImple";
import { DtoRequestTmppaysGetResImple } from "furukawa-common/src/common/dto/DtoRequestTmppaysGetResImple";
import { DtoRequestOvertimesPutReq } from "furukawa-common/src/common/dto/abst/DtoRequestOvertimesPutReq";
import { DtoRequestOvertimesPutRes } from "furukawa-common/src/common/dto/abst/DtoRequestOvertimesPutRes";
import { DtoRequestTmppaysPostReqImple } from "furukawa-common/src/common/dto/DtoRequestTmppaysPostReqImple";
//申請ヘッダ
import { Cl_request } from "@/functions/Cl_request";
import { DtoRequestsPutReqImple } from "furukawa-common/src/common/dto/DtoRequestsPutReqImple";
import { DtoRequestsPutResImple } from "furukawa-common/src/common/dto/DtoRequestsPutResImple";
//store
import store from "@/store";
import { CommonStaff } from "furukawa-common/src/common/service/CommonStaff";
// バリデーション
import * as Yup from "yup";
// import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "furukawa-common/src/common/dto/DtoFileMastersGetReqImple";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { EntityStaff } from "../../../furukawa-common/src/common/entity/EntityStaff";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
  },
})
export default class shinsei_Karibaraiedit extends Vue {
  //----------------------
  //バリデーション
  //----------------------
  //スキーマ宣言
  schemaAdd = Yup.object().shape({
    tmppayDate: Yup.string().required("仮払希望日を入力してください"),
    tmppayDateExp: Yup.string().required("精算予定日を入力してください"),
    tmppayPur: Yup.string().required("仮払目的を入力してください"),
  });
  schemaAdd_price = Yup.object().shape({
    tmppayPrice: Yup.number()
      .nullable()
      .required("仮払金額を半角数字で入力してください")
      .typeError("仮払金額を半角数字で入力してください"),
  });

  //エラー格納用
  errors: Yup.ValidationError | null = null;

  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoRequestTmppaysGetResImple();
  delegate4list = new DtoMastersGetRes();
  btnDisable = false;
  isAdmin = false;
  isMine = false;
  isEditMode = true;

  domApplicantName = "";
  domContent = "";
  domTmppay_price = "";
  domTmppay_purpose = "";

  //日付関係
  domTmppay_date: string | undefined = ""; //delegate.c_request_tmppay_date
  domTmppay_date_exp: string | undefined = ""; //delegate.c_request_tmppay_date_exp_payment
  domDateDaikyuSyujitu: string | undefined = ""; //delegate.c_request_overtime_compday_all
  domDateDaikyuBubun: string | undefined = ""; //delegate.c_request_overtime_compday_part

  //承認フォームの表示非表示フラグ
  fmShowConf = false;
  fmShowConfEx = false;
  fmShowSasimodosi = false;
  fmShowTorisage = false;

  optionsOp = [
    { label: "ユーザー1", code: 1 },
    { label: "ユーザー2", code: 2 },
    { label: "ユーザー3", code: 3 },
  ];

  optionsOp2 = [
    { label: "営業部", code: 1 },
    { label: "営業推進部", code: 2 },
  ];

  //----------------------
  //DOM連携用メソッド
  //----------------------
  convDateTime(val: Date | null): string {
    let res = "";

    if (val == null) {
      res = "";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  /**
   * 承認者の順序を入力して、承認者名を得る
   * @param num
   */
  getAuthoriser(num: number): string {
    let res = "-";

    if (this.delegate4list.items4staffs == null) {
      return "-";
    }

    switch (num) {
      case 1:
        if (this.delegate.request.c_request_1_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_1_approval
          );
        }

        break;
      case 2:
        if (this.delegate.request.c_request_2_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_2_approval
          );
        }

        break;
      case 3:
        if (this.delegate.request.c_request_3_approval == null) {
          res = "-";
        } else {
          res = CommonStaff.cnvStaffNum2Name(
            this.delegate4list.items4staffs,
            this.delegate.request.c_request_3_approval
          );
        }

        break;
      default:
        break;
    }

    return res;
  }

  /**
   * 承認日時を受け取り、表示用の文字列にして返す
   * @param val
   */
  getAuthDate(authorizerId: number | null, val: Date | null): string {
    let res = "";

    if (authorizerId == null) {
      return "-";
    }

    if (val == null) {
      res = "未承認";
    } else {
      res = val
        .toString()
        .replaceAll("-", "/")
        .replace("T", " ")
        .replace(".000Z", "");
    }

    return res;
  }

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  /**
   * 社員の選択肢を構築する用
   */
  getOpStaffs(): EntityStaff[] {
    return this.delegate4list.getStaffSortNum4NoneDelete([
      this.delegate.request.c_staff_id!,
    ]);
  }
  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";
  message4ModalDisableConf = "";
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
    await this.get();
  }
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;
      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  async get() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //パラメータにIDが指定されていなければ、新規追加モードでデータを読み込まない
      //-------
      let id = Cl_common.getParam("id");
      store.commit("revStore");
      const authType = store.state.authType;
      // Cl_common.logger(authType);
      this.isAdmin = authType == 4; //管理者判定

      if (id == "") {
        //新規追加
        this.isEditMode = false;

        //初期値設定
        this.delegate.request.c_staff_id = store.state.id;
        // this.delegate.c_request_overtime_type = 1;
        this.fmShowConf = false;
        this.fmShowConfEx = false;

        return;
      } else {
        //編集
        this.isEditMode = true;
      }

      //準備
      //-------
      let dto = new DtoRequestTmppaysGetReqImple();

      //※fanctions内のクラスの修正コスト軽減の為、c_request_overtime_idに入れているが、
      //  API側ではrequestIDとして扱われるので注意
      dto.c_request_tmppay_id = Number(id);

      //API実行
      //-------
      this.delegate = await Cl_requesttmppay.get(dto);
      //レスポンス処理
      //-------
      //IDがnullなら一覧へ遷移
      if (this.delegate.c_request_tmppay_id == null) {
        this.$router.push("/shinseisyolist");
      }

      //UI連携用変数に入力
      //---
      await this.cnvData4Get();

      //フラグ更新
      //---
      store.commit("revStore");
      const loginStaffId = store.state.id;
      this.isMine = loginStaffId == this.delegate.request.c_staff_id!; //申請者判定

      //承認関係の表記
      //---
      await this.chgAuthCont();

      //金額カンマ
      //---
      this.kanmaIns();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　GET用。
   */
  async cnvData4Get() {
    //申請者
    this.domApplicantName = CommonStaff.cnvStaffNum2Name(
      this.delegate4list.items4staffs,
      this.delegate.request.c_staff_id
    );
    //仮払金額
    if (this.delegate.c_request_tmppay_price != null) {
      this.domTmppay_price = this.delegate.c_request_tmppay_price.toString();
    }
    //仮払希望日
    if (this.delegate.c_request_tmppay_date != null) {
      this.domTmppay_date = this.delegate
        .c_request_tmppay_date!.toString()
        .split("T")[0];
    }
    //精算予定日
    if (this.delegate.c_request_tmppay_date_exp_payment != null) {
      this.domTmppay_date_exp = this.delegate
        .c_request_tmppay_date_exp_payment!.toString()
        .split("T")[0];
    }
    //仮払目的
    if (this.delegate.c_request_tmppay_cont != null) {
      this.domTmppay_purpose = this.delegate.c_request_tmppay_cont;
    }
  }

  /**
   * 承認に関するコンテンツを制御する
   */
  async chgAuthCont() {
    let loginStaffId = -1;

    switch (this.delegate.request.c_request_status) {
      case CONST_REQUEST_STATUS_SINSEITYU:
        //申請中
        //-----------
        store.commit("revStore");
        loginStaffId = store.state.id;
        if (
          (loginStaffId == this.delegate.request.c_request_1_approval &&
            this.delegate.request.c_request_1_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_2_approval &&
            this.delegate.request.c_request_2_approval_datetime == null) ||
          (loginStaffId == this.delegate.request.c_request_3_approval &&
            this.delegate.request.c_request_3_approval_datetime == null)
        ) {
          //自身が承認者で未承認
          //--------
          this.fmShowConf = true;
          this.fmShowConfEx = false;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        } else {
          //自身が承認者でない、もしくは承認済み
          //--------
          this.fmShowConf = false;
          this.fmShowConfEx = true;
          this.fmShowSasimodosi = false;
          this.fmShowTorisage = false;
        }

        break;
      case CONST_REQUEST_STATUS_SASIMODOSI:
        //差戻
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = true;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_SYOUNINZUMI:
        //承認済
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = false;
        break;
      case CONST_REQUEST_STATUS_TORISAGE:
        //取下
        //-----------
        this.fmShowConf = false;
        this.fmShowConfEx = false;
        this.fmShowSasimodosi = false;
        this.fmShowTorisage = true;

        break;
      default:
        throw new Error("不正なstatus");
    }
  }

  /**
   * データを更新する
   */
  async upd(isDel: boolean, isDisable: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isDel) {
        //削除処理：削除フラグを立てる。
        dto.c_request_flag_del = 1;
      } else {
        //差戻処理：ステータス変更。
        dto.c_request_status = 4;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isDel) {
        //削除処理：
        this.message4ModalSuccess = "正常に削除されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に変更されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを更新する
   * 承認・差戻用
   */
  async upd4Approval(isApproval: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      // //準備
      // //-------
      let dto = new DtoRequestsPutReqImple();
      let id = Cl_common.getParam("id");
      dto.c_request_id = Number(id);

      if (isApproval) {
        //承認
        dto.isApproval = 1;
      } else {
        //差戻
        dto.isApproval = 0;
      }

      //API実行
      //-------
      await Cl_request.put(dto);
      //レスポンスの処理
      //-------

      //更新処理：
      //モーダルのメッセージを設定
      if (isApproval) {
        //削除処理：
        this.message4ModalSuccess = "正常に承認されました";
      } else {
        //更新処理：
        this.message4ModalSuccess = "正常に差戻されました";
      }

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データを新たに登録する
   */
  async add() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //金額のカンマを削除
      this.kanmaDel();

      //バリデーション
      //-----------------
      this.errors = null;
      this.schemaAdd_price.validateSync({
        tmppayPrice: this.domTmppay_price,
      });
      this.schemaAdd.validateSync({
        tmppayDate: this.domTmppay_date,
        tmppayDateExp: this.domTmppay_date_exp,
        tmppayPur: this.domTmppay_purpose,
      });

      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      //UI連携用変数からdelegateに戻す
      //---
      await this.cnvData4Add();

      let dto = Object.assign(
        new DtoRequestTmppaysPostReqImple(),
        this.delegate
      );

      //API実行
      //-------
      const res = await Cl_requesttmppay.post(dto);

      //レスポンスの処理
      //-------
      //編集画面のURL作成
      this.$router.push({
        path: "/shinsei_Karibaraiedit",
        query: { id: res.c_request_id },
      });

      //モーダルのメッセージを設定
      this.message4ModalSuccess = "正常に追加されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success;
      modal.showModal();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        //バリデーション
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = error.errors[0];
        this.errors = error;
      } else {
        //それ以外
        //---------------
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "エラーが発生しました";
      }
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * DOM連携用の変換処理　ADD用。
   */
  async cnvData4Add() {
    //仮払金額
    if (this.domTmppay_price != null) {
      this.delegate.c_request_tmppay_price = Number(this.domTmppay_price);
    }
    //仮払希望日
    if (this.domTmppay_date != undefined) {
      this.delegate.c_request_tmppay_date = new Date(this.domTmppay_date);
    }
    //精算予定日
    if (this.domTmppay_date_exp != undefined) {
      this.delegate.c_request_tmppay_date_exp_payment = new Date(
        this.domTmppay_date_exp
      );
    }
    //仮払目的
    if (this.domTmppay_purpose != null) {
      this.delegate.c_request_tmppay_cont = this.domTmppay_purpose;
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");
      //準備
      //-------
      let dto = new DtoFileMastersGetReqImple();
      dto.type = "Karibarai";
      let id = Cl_common.getParam("id");
      dto.requestId = Number(id);
      //API実行
      //-------
      const res = await Cl_filemaster.get(dto);
      //レスポンス処理
      //-------
      location.href = res.url;
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf() {
    //モーダルのメッセージを設定
    this.message4ModalDelConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }

  /**
   * 取り下げ用のモーダルを表示
   */
  async disableConf() {
    //モーダルのメッセージを設定
    this.message4ModalDisableConf =
      "【" +
      this.delegate.c_request_id +
      "】" +
      this.delegate.request.c_request_name +
      "を取り下げます。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.showModal();
  }

  /**
   * データを削除する
   */
  async del() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4deleteConf();
    this.upd(true, false);
  }
  /**
   * データを取り下げる
   */
  async disab() {
    //削除フラグのあるテーブルなので更新処理。
    this.closeModal4disableConf();
    this.upd(false, true);
  }

  /**
   * エクセル出力ボタンの表示・非表示を制御する。
   */
  isShowExportBtn() {
    if (!this.isEditMode) {
      return false;
    }

    //データがなければ非表示
    if (this.delegate == null || this.delegate.c_request_id == null) {
      return false;
    }

    //差戻かつ本人でない場合、非表示
    store.commit("revStore");
    const loginStaffId = store.state.id;
    if (
      this.delegate.request.c_request_status ==
        CONST_REQUEST_STATUS_SASIMODOSI &&
      this.delegate.request.c_staff_id != loginStaffId
    ) {
      return false;
    }

    //承認済みステータスであれば表示
    if (
      this.delegate.request.c_request_status == CONST_REQUEST_STATUS_SYOUNINZUMI
    ) {
      return true;
    }

    //一人でも承認して入れば表示
    if (
      this.delegate.request.c_request_1_approval_status ==
        CONST_APPROVAL_STATUS_SYOUNIN ||
      this.delegate.request.c_request_2_approval_status ==
        CONST_APPROVAL_STATUS_SYOUNIN ||
      this.delegate.request.c_request_3_approval_status ==
        CONST_APPROVAL_STATUS_SYOUNIN
    ) {
      return true;
    }

    // それ以外は非表示
    return false;
  }

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel() {
    //カンマを消す
    if (this.domTmppay_price == null) {
      this.domTmppay_price = "";
    }
    this.domTmppay_price = this.domTmppay_price.replace(/,/g, "");
    if (this.domTmppay_price == "0") this.domTmppay_price = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns() {
    if (this.domTmppay_price == null) {
      return;
    }
    let val: string = this.domTmppay_price;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    this.domTmppay_price = num;
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }
  async closeModal4disableConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_disableConf;
    modal.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
