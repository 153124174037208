
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import { Cl_common } from "@/functions/Cl_common";
import { DtoFileReportsGetReqImple } from "furukawa-common/src/common/dto/DtoFileReportsGetReqImple";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { DtoOutputqueuesGetReqImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetResImple";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";

@Options({
  components: {
    vSelect,
    Mo_modal_alert,
    Mt_dtp,
  },
})
export default class FinalABCList extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  domDate_start = new DtoMdDtp();
  domDate_end = new DtoMdDtp();
  delegate4list = new DtoMastersGetRes();
  domBranchId = "";

  //ボタン制御用
  btnDisable = false;

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getMastersList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *拠点プルダウン取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索条件設定
      dtoMaster.getBranch = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate4list);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //入力チェック
      //-------------------------
      // 期間
      if (null == this.domDate_start.date || null == this.domDate_end.date) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "期間をを指定してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      // 拠点
      if ("" == this.domBranchId || null == this.domBranchId) {
        //モーダルのメッセージを設定
        this.message4ModalErrorConf = "拠点をを指定してください";
        //モーダル表示
        const modal: any = this.$refs.Mo_modal_errorConf;
        modal.showModal();
        return;
      }

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "matchingList";

      //条件設定
      //-----
      if (this.domDate_start.date != null) {
        dto.date_start = new Date(this.domDate_start.date);
      }

      if (this.domDate_end.date != null) {
        dto.date_end = new Date(this.domDate_end.date);
      }

      if (this.domBranchId != "" && this.domBranchId != null)
        dto.branchId = Number(this.domBranchId);

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);

      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      Cl_common.logger("res.outputqueue_code");
      Cl_common.logger(res.outputqueue_code);

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dtoOutputReq = new DtoOutputqueuesGetReqImple();
      dtoOutputReq.c_outputqueue_code = res.outputqueue_code;
      let resOutputReq = new DtoOutputqueuesGetResImple();
      resOutputReq = await Cl_outputqueue.get(dtoOutputReq);
      while (now < timeLimit) {
        Cl_common.logger("wait request");
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        resOutputReq = await Cl_outputqueue.get(dtoOutputReq);
        //↓社領修正行
        if (resOutputReq.c_outputqueue_flag_end == 1) {
          break;
        }
      }

      //レスポンス処理
      //-------
      Cl_common.logger("resOutputReq");
      Cl_common.logger(resOutputReq);

      //↓社領修正行
      if (resOutputReq.c_outputqueue_flag_success == 1) {
        location.href = resOutputReq.url;
      } else {
        throw new Error("出力失敗");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
