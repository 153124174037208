
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
// import { CONST_OP_SUPPLY_TYPE } from "furukawa-common/src/common/constant/Constants";
import { DtoSearchSupplysGetRes } from "furukawa-common/src/common/dto/DtoSearchSupplysGetRes";
import { DtoSearchSupplysGetReq } from "furukawa-common/src/common/dto/DtoSearchSupplysGetReq";
import { DtoMastersGetRes } from "furukawa-common/src/common/dto/DtoMastersGetRes";
import { Cl_common } from "@/functions/Cl_common";
import { Cl_master } from "@/functions/Cl_master";
import { DtoMastersgetReq } from "furukawa-common/src/common/dto/DtoMastersGetReq";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import { Cl_searchSupplys } from "@/functions/Cl_searchSupplys";
import { DtoMdDtp } from "furukawa-common/src/common/dto/DtoMdDtp";
import Mt_dtp4month from "@/components/atoms/Mt_dtp4month.vue";
import Mt_dtp from "@/components/atoms/Mt_dtp.vue";
import {
  CONST_CLAIM_ROUDING_SISYAGONYU,
  CONST_OP_CHKCLAIM,
  CONST_OP_ITEMTYPE_BREAKDOWNS,
  CONST_OP_ITEMTYPE_ITEM,
  CONST_OP_ITEMTYPE_SUBJECTS,
  CONST_OP_PROJECT_TYPE,
  CONST_SORT_ORDER_ASC,
  CONST_SORT_ORDER_DESC,
} from "furukawa-common/src/common/constant/Constants";
import { DtoFileReportsGetReqImple } from "furukawa-common/src/common/dto/DtoFileReportsGetReqImple";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { DtoOutputqueuesGetResImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetResImple";
import { DtoOutputqueuesGetReqImple } from "furukawa-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";
import { CommonPrice4FU } from "furukawa-common/src/common/function/CommonPrice4FU";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_alert,
    Mt_dtp,
    Mt_dtp4month,
  },
})
export default class SupplierSearch extends Vue {
  /**
   * パフォーマンス問題の解決案としてテスト的に実装中
   * 残課題：グローバルなメソッドとして宣言して、どこからでも呼び出せるようにしたい
   * 　　　　色々なパターンでも問題ないかの検証
   * @param url
   * @param event
   */
  goTo(url: string, event?: MouseEvent) {
    if (event && (event.ctrlKey || event.metaKey)) {
      window.open(url);
    } else {
      this.$router.push(url);
    }
  }
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoSearchSupplysGetRes();
  delegate4list = new DtoMastersGetRes();
  delegateStaff = new DtoMastersGetRes();
  delegateClient = new DtoMastersGetRes();
  delegateSupplier = new DtoMastersGetRes();

  //ボタン制御用
  btnDisable = false;
  //検索条件dom
  domProjectNo_start = "";
  domProjectNo_end = "";
  domProjectName = "";
  domProClientid = "";
  domProjectSponsorName = "";
  domProStaffId = "";
  domProjectType = "";
  domClaimNo_start = "";
  domClaimNo_end = "";
  domSupplyMonth_start = new DtoMdDtp();
  domSupplyMonth_end = new DtoMdDtp();
  domSupplyItem = "";
  domSupplySubject = "";
  domSupplyBreakdown = "";
  domSupplyRemarks = "";
  domSupplierid = "";
  domSupplyDateExpPayment_start = new DtoMdDtp();
  domSupplyDateExpPayment_end = new DtoMdDtp();
  domSalesRemarks = "";
  domBranchId = "";

  domVariationType = "";
  domItemType = "";
  domChkClaim = "";

  strVariationType = [
    "",
    "媒体・製作施工",
    "媒体・施工",
    "製作施工",
    "媒体費",
    "施工費",
    "取付費",
    "撤去費",
    "撤去（前受）",
    "プリント",
    "製作費",
    "工事（資産）",
    "電気代",
    "申請費",
    "その他",
  ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opPjtype = CONST_OP_PROJECT_TYPE;
  opFormCategory = CONST_OP_ITEMTYPE_ITEM;
  opFormSubject = CONST_OP_ITEMTYPE_SUBJECTS;
  opFormBreakdown = CONST_OP_ITEMTYPE_BREAKDOWNS;
  opChkClaim = CONST_OP_CHKCLAIM;

  columns = [
    {
      label: "詳細",
      field: "btnDet",
      thClass: "text-center",
      tdClass: "text-center",
      // width: "1%",
    },
    {
      label: "案件番号",
      field: "c_project_number",
      thClass: "text-center",
    },
    {
      label: "拠点",
      field: "c_branch_name",
      thClass: "text-center",
      width: "10%",
    },
    {
      label: "案件名",
      field: "c_project_name",
      thClass: "text-center",
      width: "10%",
    },
    {
      label: "広告主",
      field: "c_project_sponsor_name",
      thClass: "text-center",
      width: "10%",
    },
    {
      label: "請求No",
      field: "c_claim_id",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "計上月",
      field: "c_supply_month",
      type: "date",
      dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      dateOutputFormat: "yyyy-MM",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "項目",
      field: "c_supply_item_name",
      thClass: "text-center",
    },
    {
      label: "科目",
      field: "c_supply_subject_name",
      thClass: "text-center",
    },
    {
      label: "内訳",
      field: "c_supply_breakdown_name",
      thClass: "text-center",
    },
    {
      label: "仕入先",
      field: "c_supplier_name",
      thClass: "text-center",
    },
    {
      label: "入力金額",
      field: "c_supply_price",
      thClass: "text-center",
      tdClass: "text-right",
    },
    {
      label: "税率",
      field: "c_supply_taxrate_name",
      thClass: "text-center",
      tdClass: "text-center",
    },
    {
      label: "税抜金額",
      field: "zeinuki",
    },
    {
      label: "税込金額",
      field: "c_supply_taxrate",
    },
    {
      label: "請求書着",
      field: "c_supply_flag_chkclaim",
    },
    {
      label: "支払予定日",
      field: "c_supply_date_exp_payment",
      type: "date",
      dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
      dateOutputFormat: "yyyy-MM-dd",
      thClass: "text-center",
    },
    {
      label: "確定",
      field: "c_supply_flag_paid",
      thClass: "text-center",
    },
    {
      label: "備考",
      field: "c_supply_remarks",
      thClass: "text-center",
      width: "10%",
    },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
    await this.getMastersList();
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   *Masters取得用
   */
  async getMastersList() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      //検索選択肢用
      dtoMaster.getStaff4sortNum = 1;
      dtoMaster.delFlag4Staff4sortNum = 1;
      // dtoMaster.getStaff = 1;
      // dtoMaster.delFlag4staff = 0;

      dtoMaster.getBranch = 1;
      dtoMaster.delFlag4Branch = 0;

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 0;

      dtoMaster.getSuppliers = 1;
      dtoMaster.delFlag4suppliers = 0;

      //カレンダー休日反映用
      dtoMaster.getHoliday = 1;

      //API実行
      //-------
      this.delegate4list = await Cl_master.get(dtoMaster);

      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました"; //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegate4list = new DtoMastersGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoSearchSupplysGetReq();

      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;
      Cl_common.logger("pager");
      Cl_common.logger(dto.pager.count);
      Cl_common.logger(dto.pager.cursor);

      if (this.domProjectNo_start != "")
        dto.projectNo_start = Number(this.domProjectNo_start);

      if (this.domProjectNo_end != "")
        dto.projectNo_end = Number(this.domProjectNo_end);

      dto.projectName = this.domProjectName;

      if (this.domProClientid != "" && this.domProClientid != null)
        dto.proClientid = Number(this.domProClientid);

      dto.projectSponsorName = this.domProjectSponsorName;

      if (this.domProStaffId != "" && this.domProStaffId != null)
        //dto.staffId = Number(this.domProStaffId);
        dto.staffId = this.domProStaffId;

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.supplyMonth_start = this.domSupplyMonth_start;

      if (this.domSupplyMonth_start.date != null) {
        dto.supplyMonth_start = new Date(this.domSupplyMonth_start.date);
      }

      // dto.supplyMonth_end = this.domSupplyMonth_end;

      if (this.domSupplyMonth_end.date != null) {
        dto.supplyMonth_end = new Date(this.domSupplyMonth_end.date);
      }

      if (this.domSupplyItem != "" && this.domSupplyItem != null)
        dto.supplyItem = Number(this.domSupplyItem);

      if (this.domSupplySubject != "" && this.domSupplySubject != null)
        dto.supplySubject = Number(this.domSupplySubject);

      if (this.domSupplyBreakdown != "" && this.domSupplyBreakdown != null)
        dto.supplyBreakdown = Number(this.domSupplyBreakdown);

      if (this.domSupplierid != "" && this.domSupplierid != null)
        dto.supplierid = Number(this.domSupplierid);

      if (this.domProjectType != "" && this.domProjectType != null)
        dto.projectType = Number(this.domProjectType);

      dto.supplyRemarks = this.domSupplyRemarks;

      // dto.supplyDateExpPayment_start = this.domSupplyDateExpPayment_start;

      if (this.domSupplyDateExpPayment_start.date != null) {
        dto.supplyDateExpPayment_start = new Date(
          this.domSupplyDateExpPayment_start.date
        );
      }

      // dto.supplyDateExpPayment_end = this.domSupplyDateExpPayment_end;

      if (this.domSupplyDateExpPayment_end.date != null) {
        dto.supplyDateExpPayment_end = new Date(
          this.domSupplyDateExpPayment_end.date
        );
      }

      if (this.domVariationType != "" && this.domVariationType != null)
        dto.VariationType = Number(this.domVariationType);

      if (this.domItemType != "" && this.domItemType != null)
        dto.ItemType = Number(this.domItemType);

      if (this.domBranchId != "" && this.domBranchId != null)
        dto.branchId = this.domBranchId;

      if (this.domChkClaim == "1" || this.domChkClaim == "0") {
        dto.chkClaim = Number(this.domChkClaim);
      }

      //API実行
      //-------
      this.delegate = await Cl_searchSupplys.getList(dto);
      //レスポンス処理
      //-------
      Cl_common.logger("結果");
      Cl_common.logger(this.delegate);
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoSearchSupplysGetRes();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   * 支払い確定フラグを受け取りhtmlを返す
   */
  cnvFlagPaid2Name(statusNum: number): string {
    switch (statusNum) {
      case 1:
        return '<span class="badge badge-info badge-pill" style="font-size: 1em;">確定</span>';
      default:
        return '<span class="badge badge-primary badge-pill" style="font-size: 1em;">未確定</span>';
    }

    // return cnvRequestStatusNum2Name(statusNum);
  }

  /**
   * ファイルをダウンロードする
   */
  async dl(sortOrder: boolean) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "ShiireList";

      if (this.domProjectNo_start != "")
        dto.projectNo_start = Number(this.domProjectNo_start);

      if (this.domProjectNo_end != "")
        dto.projectNo_end = Number(this.domProjectNo_end);

      dto.projectName = this.domProjectName;

      if (this.domProClientid != "" && this.domProClientid != null)
        dto.proClientid = Number(this.domProClientid);

      dto.projectSponsorName = this.domProjectSponsorName;

      if (this.domProStaffId != "" && this.domProStaffId != null)
        //dto.staffId = Number(this.domProStaffId);
        dto.staffId = this.domProStaffId;

      if (this.domClaimNo_start != "")
        dto.claimNo_start = Number(this.domClaimNo_start);

      if (this.domClaimNo_end != "")
        dto.claimNo_end = Number(this.domClaimNo_end);

      // dto.supplyMonth_start = this.domSupplyMonth_start;

      if (this.domSupplyMonth_start.date != null) {
        dto.supplyMonth_start = new Date(this.domSupplyMonth_start.date);
      }

      // dto.supplyMonth_end = this.domSupplyMonth_end;

      if (this.domSupplyMonth_end.date != null) {
        dto.supplyMonth_end = new Date(this.domSupplyMonth_end.date);
      }

      if (this.domSupplyItem != "" && this.domSupplyItem != null)
        dto.supplyItem = Number(this.domSupplyItem);

      if (this.domSupplySubject != "" && this.domSupplySubject != null)
        dto.supplySubject = Number(this.domSupplySubject);

      if (this.domSupplyBreakdown != "" && this.domSupplyBreakdown != null)
        dto.supplyBreakdown = Number(this.domSupplyBreakdown);

      if (this.domSupplierid != "" && this.domSupplierid != null)
        dto.supplierid = Number(this.domSupplierid);

      if (this.domProjectType != "" && this.domProjectType != null)
        dto.projectType = Number(this.domProjectType);

      dto.supplyRemarks = this.domSupplyRemarks;

      // dto.supplyDateExpPayment_start = this.domSupplyDateExpPayment_start;

      if (this.domSupplyDateExpPayment_start.date != null) {
        dto.supplyDateExpPayment_start = new Date(
          this.domSupplyDateExpPayment_start.date
        );
      }

      // dto.supplyDateExpPayment_end = this.domSupplyDateExpPayment_end;

      if (this.domSupplyDateExpPayment_end.date != null) {
        dto.supplyDateExpPayment_end = new Date(
          this.domSupplyDateExpPayment_end.date
        );
      }

      if (this.domVariationType != "" && this.domVariationType != null)
        dto.VariationType = Number(this.domVariationType);

      if (this.domItemType != "" && this.domItemType != null)
        dto.ItemType = Number(this.domItemType);

      if (this.domBranchId != "" && this.domBranchId != null)
        dto.branchId = Number(this.domBranchId);

      if (
        // this.domChkClaim != "" &&
        // this.domChkClaim != null &&
        this.domChkClaim == "1" ||
        this.domChkClaim == "0"
      ) {
        dto.chkClaim = Number(this.domChkClaim);
      }

      // 引数に応じてソート方法を切り替える
      dto.sortorder = sortOrder ? CONST_SORT_ORDER_DESC : CONST_SORT_ORDER_ASC;

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);
      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  /**
   *社員プルダウン取得用
   */
  async getStaffList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行社員マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getStaff = 1;
      dtoMaster.delFlag4staff = 0;

      //API実行
      //-------
      this.delegateStaff = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateStaff);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateStaff = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  /**
   *顧客プルダウン取得用
   */
  async getClientList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行顧客マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getClients = 1;
      dtoMaster.delFlag4clients = 0;

      //API実行
      //-------
      this.delegateClient = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateClient);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateClient = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  /**
   *仕入れ先プルダウン取得用
   */
  async getSupplierList() {
    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行仕入れ先マスタ取得");

      //準備
      //-------
      let dtoMaster = new DtoMastersgetReq();

      dtoMaster.getSuppliers = 1;
      dtoMaster.delFlag4suppliers = 0;

      //API実行
      //-------
      this.delegateSupplier = await Cl_master.get(dtoMaster);
      // Cl_common.logger("結果");
      // Cl_common.logger(this.delegateSupplier);
      //レスポンス処理
      //-------
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      this.delegateSupplier = new DtoMastersGetRes();
      Cl_common.logger(error);
    }
  }

  /**
   * 税抜き金額を計算する
   * @param salesPrice
   * @param taxRate
   */
  cnvExcludingTaxPrice(salesPrice: any, taxRate: any) {
    let res;

    let rounding = CONST_CLAIM_ROUDING_SISYAGONYU;

    //金額
    if (salesPrice != null) {
      res = CommonPrice4FU.calcExcludTax(salesPrice, taxRate, rounding);
    } else {
      res = "";
    }
    return res;
  }
  /**
   * 税込み金額を計算する
   * @param salesPrice
   * @param taxRate
   */
  cnvIncludingTaxPrice(Price: any, taxRate: any) {
    let res;

    let rounding = CONST_CLAIM_ROUDING_SISYAGONYU;

    //金額
    if (Price != null) {
      res = CommonPrice4FU.calcTax(Price, taxRate, rounding);
    } else {
      res = "";
    }
    return res;
  }

  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/AnkenInput?id=" + id);
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   *請求書着フラグを受け取り、アイコン用のHTMLを返す
   */
  cnvChkClaim2Icon(flagNum: number | null): string {
    if (flagNum == 1) {
      return '<span  class="badge badge-success badge-pill" style="font-size: 1em;">確認済</span>';
    } else {
      return '<span  class="badge badge-dark badge-pill" style="font-size: 1em;">未確認</span>';
    }
  }
  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
